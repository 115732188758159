import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "./../../store";
import { API_URL } from "../../config";

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Accept", "application/json, text/plain, */*");
    const token = (getState() as RootState)?.auth?.token ?? window.localStorage.getItem("access_token");
    // If we have a token in localStorage, lets use it:
    headers.set("Authorization", `Bearer ${token}`);
    headers.set("token", `${process.env.REACT_APP_TOKEN}`);
    return headers;
  },
});

export const appApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});
