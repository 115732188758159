import { Outlet } from "react-router-dom";
import DefaultHeader from "./header";
import DefaultFooter from "./footer";
import AppHeader from "./layout/AppHeader";
import AppSidebar from "./layout/AppSidebar";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";

const DefaultLayout = () => {
  const { sidebarShow } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );
  
  return (
    <div>
      <AppSidebar />
      <div className={`${sidebarShow && "wrapper"} d-flex flex-column min-vh-100 bg-light`}>
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <Outlet />
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  );
};

export default DefaultLayout;
