import React from "react";
import { Loader, Placeholder } from "rsuite";

const Loading = () => {
  return (
    <div>
      <Placeholder.Paragraph rows={8} />
      <Loader backdrop content="loading..." vertical size="lg" />
    </div>
  )
};
export default Loading;
