import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { router } from "./routes";
import { store } from "./store";
import reportWebVitals from './reportWebVitals';
import '@coreui/coreui/dist/css/coreui.min.css';
import "rsuite/dist/rsuite.min.css";
import 'react-notifications/lib/notifications.css';
import './styles/index.css'
import 'bootstrap/dist/css/bootstrap.min.css'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
