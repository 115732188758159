import { createSlice } from "@reduxjs/toolkit";

interface initialState {
  revenue:number,
  sidebarShow:boolean
}

const initialState: initialState = {
  revenue: 0,
  sidebarShow:true
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setRevenue: (state, action) => {
      state.revenue = action.payload;
    },
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload;
    },
  },
});

export const { 
  setRevenue,
  setSidebarShow
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
