import React from 'react'

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
  CNavTitle,
  CNavItem,
  CBadge,
  CAvatar
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilSpeedometer } from '@coreui/icons';

import { logout } from '../../../../store/auth/authStore'

import logoNegative from './../../../../assets/images/logo/home-grown-collective.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const AppSidebar = () => {
  
  const { sidebarShow } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();

  function userLogout() {
    dispatch(logout());
    localStorage.removeItem("access_token");
    navigate("/auth/login");
  }

  return (
    <CSidebar 
      position="fixed"
      className="sidebar-fixed"
      visible={sidebarShow}
      >
      <CSidebarBrand><CAvatar src={logoNegative} size="xl" /></CSidebarBrand>
      <CSidebarNav>
        <CNavTitle>Navigate To</CNavTitle>
          <li className='nav-item'>
            <Link to="/admin/dashboard" className={`nav-link ${ (location?.pathname?.split('/'))?.includes('dashboard') ? "active" : ""}`}>
              <CIcon customClassName="nav-icon" icon={cilSpeedometer} />
              Dashboard
            </Link>
          </li>
          <li className='nav-item'>
            <Link to="/consumer/list" className={`nav-link ${ (location?.pathname?.split('/'))?.includes('consumer') ? "active" : ""}`}>
              <i className="fa fa-users nav-icon"></i>
              Consumers
            </Link>
          </li>
          <li className='nav-item'>
            <Link to="/producer/list" className={`nav-link ${ (location?.pathname?.split('/'))?.includes('producer') ? "active" : ""}`}>
              <i className="fa fa-user nav-icon"></i>
              Producers
            </Link>
          </li>
          <li className='nav-item'>
          <Link to="/collaboration" className={`nav-link ${ (location?.pathname?.split('/'))?.includes('collaboration') ? "active" : ""}`}>
            <i className="fa fa-cart-arrow-down nav-icon" aria-hidden="true"></i>
            Collaborations
          </Link>
        </li>
        <li className='nav-item'>
          <Link to="/order/list" className={`nav-link ${ (location?.pathname?.split('/'))?.includes('order') ? "active" : ""}`}>
            <i className="fa fa-cart-arrow-down nav-icon" aria-hidden="true"></i>
            Orders
          </Link>
        </li>
        <li className='nav-item'>
          <Link to="/category/list" className={`nav-link ${ (location?.pathname?.split('/'))?.includes('category') ? "active" : ""}`}>
            <i className="fa fa-snowflake-o nav-icon" aria-hidden="true"></i>
            Category Management
          </Link>
        </li>
        <li className='nav-item'>
          <Link to="/reset-password" className={`nav-link ${ (location?.pathname?.split('/'))?.includes('reset-password') ? "active" : ""}`}>
            <i className="fa fa-solid fa-lock nav-icon" aria-hidden="true"></i>
            Reset Password
          </Link>
        </li>

        <CNavItem href="/settings" onClick={(e) => {
            userLogout();
          }}>
          <i className="fa fa-sign-out nav-icon" aria-hidden="true"></i>
          Logout
        </CNavItem>
      </CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
