import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { RootState } from "./../store";
import { useGetUserQuery } from "../store/auth/authApi";
import { logout } from "./../store/auth/authStore";
import Loading from "../components/loading";

export default function AuthGuard({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );
  
  const dispatch = useDispatch();
  const {
    data: userData,
    isLoading,
    error,
  } = useGetUserQuery();


  if (isLoading) {
    return <Loading/>;
  }

  if (!isAuthenticated) {
    dispatch(logout());
    return <Navigate to={"/auth/login"} />;
  } 
  return <>{children}</>;
}
