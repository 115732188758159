import { createSlice } from "@reduxjs/toolkit";
import { IUserFullDetails } from "./../../types/auth";

interface initialState {
  user: IUserFullDetails | null;
  isAuthenticated: boolean;
  token: null | string;
  otpEmail?:string,
}

const initialState: initialState = {
  isAuthenticated: localStorage.getItem("access_token") ? true : false,
  user: null,
  token: null,
  otpEmail:''
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
    },
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.token = null;
    },
    setOtpEmail: (state, action) => {
      state.otpEmail = action.payload;
    }
  },
});

export const { loginSuccess, logout, setOtpEmail } = authSlice.actions;

export default authSlice.reducer;
