import { createBrowserRouter } from "react-router-dom";
import { lazy, LazyExoticComponent, Suspense } from "react";
import DashboardLayout from "./../components/layouts/default";
import GuestGuard from './../guards/GuestGuard'
import Loading from "./../components/loading";
import AuthLayout from "../components/layouts/auth";
/* @ts-ignore */
import AuthGuard from "../guards/AuthGuard";
import { ToastContainer } from 'react-toastify';

const Loadable =
  (Component: LazyExoticComponent<() => JSX.Element>) => (props: any) => {
    return (
      <Suspense fallback={<Loading />}>
        <ToastContainer />
        <Component {...props} />
      </Suspense>
    );
  };
/** login route */
const Login = Loadable(
  lazy(() => import("./../pages/auth/Login"))
);
/** forget password route */
const ForgotPassword = Loadable(
  lazy(() => import("./../pages/auth/ForgotPassword"))
);
/** conform otp route */
const ConformOtp = Loadable(
  lazy(() => import("./../pages/auth/ConformOtp"))
);
/** change password route */
const ChangePassword = Loadable(
  lazy(() => import("./../pages/auth/ChangePassword"))
);
/** dashbaord route */
const AddDashboard = Loadable(
  lazy(() => import("./../pages/dashboard"))
);

/** Consumer List route */
const ConsumerList = Loadable(
  lazy(() => import("./../pages/consumer"))
);

/** Consumer List route */
const ProducerList = Loadable(
  lazy(() => import("./../pages/producer"))
);

/** view consumer/producer List route */
const ViewUserDetails = Loadable(
  lazy(() => import("./../pages/consumer/View"))
);

/** edit consumer/producer List route */
const EditUserDetails = Loadable(
  lazy(() => import("./../pages/consumer/Edit"))
);

/** view producer/producer List route */
const ViewProducerDetails = Loadable(
  lazy(() => import("./../pages/producer/View"))
);

/** view order components */
const OrderList = Loadable(
  lazy(() => import("./../pages/order"))
);

/** view order details components */
const OrderDetails = Loadable(
  lazy(() => import("./../pages/order/Details"))
);

/** edit producer List route */
const EditProducerDetails = Loadable(
  lazy(() => import("./../pages/producer/Edit"))
);

/** Category list List route */
const CategoryList = Loadable(
  lazy(() => import("./../pages/category"))
);

/** Category edit page route */
const CategoryEdit = Loadable(
  lazy(() => import("./../pages/category/Edit"))
);

/** Category add page route */
const CategoryAdd = Loadable(
  lazy(() => import("./../pages/category/Add"))
);


/** settings page route */
const Settings = Loadable(
  lazy(() => import("./../pages/settings"))
);

const Collaboration = Loadable(lazy(() => import("./../pages/collaborations")));

/** view group details components */
const GroupDetails = Loadable(
  lazy(() => import("./../pages/collaborations/Details"))
);

/** reset password components */
const ResetPassword = Loadable(
  lazy(() => import("./../pages/resetPassword"))
);

export const router = createBrowserRouter([
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "confirm-otp",
        element: <ConformOtp />,
      },
      {
        path: "change-password/:token",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true,
        element: <Login />,
      }
    ],
  },
  {
    path: "/admin/dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AddDashboard />,
      }
    ],
  },
  {
    path: "/consumer/list",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <ConsumerList />,
      }
    ],
  },
  {
    path: "/producer/list",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <ProducerList />,
      }
    ],
  },
  {
    path: "/order/list",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <OrderList />,
      }
    ],
  },
  {
    path: "/consumer/view/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <ViewUserDetails />,
      }
    ],
  },
  {
    path: "/producer/view/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <ViewProducerDetails />,
      }
    ],
  },
  {
    path: "/consumer/edit/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <EditUserDetails />,
      }
    ],
  },
  {
    path: "/producer/edit/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <EditProducerDetails />,
      }
    ],
  },
  {
    path: "/order/details/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <OrderDetails />,
      }
    ],
  },
  {
    path: "/category/list/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <CategoryList />,
      }
    ],
  },
  {
    path: "/category/edit/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <CategoryEdit />,
      }
    ],
  },
  {
    path: "/category/add/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <CategoryAdd />,
      }
    ],
  },
  {
    path: "/settings",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Settings />,
      }
    ],
  },
  {
    path: "/collaboration",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Collaboration />
      }
    ]
  },
  {
    path: "/collaboration/details/:id",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <GroupDetails />,
      }
    ],
  },
  {
    path: "/reset-password",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <ResetPassword />
      }
    ]
  },
]);