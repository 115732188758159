import { combineReducers } from "redux";
import { appApi } from "./apis/apiSlice";

import authReducer from './../store/auth/authStore'
import userReducer from './../store/user/userStore'
import dashboardReducer from './../store/dashboard/dashboardStore'
import CategoryReducer from './../store/category/categoryStore'
// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
  auth: authReducer,
  user:userReducer,
  dashboardSlice: dashboardReducer,
  categorySlice:CategoryReducer
});

export { rootReducer };
