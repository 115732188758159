import { createSlice } from "@reduxjs/toolkit";
import { IUserFullDetails } from "./../../types/auth";

interface initialState {
  isAuthenticated: boolean;
  isDelete:boolean,
  deleteUserId: number,
  token:string
}

const initialState: initialState = {
  isAuthenticated: localStorage.getItem("access_token") ? true : false,
  isDelete:false,
  deleteUserId: 0,
  token:localStorage.getItem("access_token") ?? ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    deleteModal: (state, action) => {
      state.isDelete = action.payload.isDelete;
      state.deleteUserId = action.payload.userId;
    },
  },
});

export const { 
  deleteModal
} = userSlice.actions;

export default userSlice.reducer;
