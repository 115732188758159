import { createSlice } from "@reduxjs/toolkit";

interface initialState {
  isDelete:boolean,
  deleteCategoryId: number,
}


const initialState: initialState = {
  isDelete:false,
  deleteCategoryId: 0,
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    deleteCategoryModal: (state, action) => {
      state.isDelete = action.payload.isDelete;
      state.deleteCategoryId = action.payload.deleteCategoryId;
    },
  },
});

export const { 
  deleteCategoryModal
} = categorySlice.actions;

export default categorySlice.reducer;
