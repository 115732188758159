import { appApi } from "../apis/apiSlice";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { IUserFullDetails, ILoginResponseInterface, ILogin, IVerifyOtp, IConfirmPassword } from "./../../types/auth";
// import { IProfileUpdate } from "types/profile";
// import { IChangePassword } from "pages/auth/change-password";

const authApiWithTag = appApi.enhanceEndpoints({
    addTagTypes: ["Auth", "USER-PROFILE"],
});

export const authApi = authApiWithTag.injectEndpoints({
    endpoints: (builder) => ({
        loginUser: builder.mutation<ILoginResponseInterface, ILogin>({
            query: ({ email, password }) => {
                localStorage.clear();
                return {
                    url: "/admin/login",
                    method: "POST",
                    body: {
                        email,
                        password,
                    },
                };
            },
            invalidatesTags: ["Auth", "USER-PROFILE"],
            transformErrorResponse: (error: any) => {
                return error;
            },
        }),
        logoutUser: builder.mutation<void, void>({
            query: () => {
                return {
                    url: "/logout",
                    method: "POST",
                };
            },

            invalidatesTags: ["Auth", "USER-PROFILE"],
        }),
        getUser: builder.query<IUserFullDetails, undefined | void>({
            query: () => "/profile",
            providesTags: ["Auth", "USER-PROFILE"],
            transformResponse: (res: { user: [IUserFullDetails] }) => {
                const user = res.user[0];
                return user;
            },
        }),
        // updateUser: builder.mutation<any, IProfileUpdate>({
        //     query: (data: IProfileUpdate) => {
        //         return {
        //             url: "profile",
        //             method: "POST",
        //             body: data,
        //         };
        //     },
        //     invalidatesTags: ["USER-PROFILE"],
        //     transformErrorResponse: (err) => {
        //         return err;
        //     },
        // }),
        forgotPassword: builder.mutation({
            query: ({ email }) => ({
                url: "/forget-password",
                method: "POST",
                accept: "application/json",
                body: { email },
            }),
            transformErrorResponse: (err) => {
                return (err.data as { errors: { email: [string] } })?.errors
                    ?.email[0];
            },
        }),
        verifyOtp: builder.mutation<void, IVerifyOtp>({
            query: ({ email, otp }) => ({
                url: "/verify-otp",
                method: "POST",
                accept: "application/json",
                body: { 
                    email,
                    otp 
                },
            })
        }),
        confirmPassword: builder.mutation<void, IConfirmPassword>({
            query: ({ password, password_confirmation, verification_token }) => ({
                url: "/set-new-password",
                method: "POST",
                accept: "application/json",
                body: { 
                    password,
                    password_confirmation,
                    verification_token 
                },
            })
        }),
        // changePassword: builder.mutation<void, IChangePassword>({
        //     query: (body) => ({
        //         url: "change-password",
        //         accept: "application/json",
        //         method: "POST",
        //         body,
        //     }),
        //     transformErrorResponse: (err: FetchBaseQueryError) => {
        //         if (err.data) {
        //             return err.data;
        //         }
        //         return err;
        //     },
        // }),
        changeProfileImage: builder.mutation<
            {
                data: string;
                message: string;
            },
            { formData: FormData }
        >({
            query: ({ formData }) => ({
                url: `/profile-image`,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: ["USER-PROFILE"],
        }),
    }),
});

export const {
    useLoginUserMutation,
    useLogoutUserMutation,
    useGetUserQuery,
    // useUpdateUserMutation,
    useForgotPasswordMutation,
    // useChangePasswordMutation,
    useChangeProfileImageMutation,
    useVerifyOtpMutation,
    useConfirmPasswordMutation
} = authApi;
